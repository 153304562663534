import React, {useEffect, useState} from "react";
import SectionTitle from "../../../../../components/core/SectionTitle";
import Widget from "../../../../../components/core/Widget";
import ContentSpinner from "../../../../../components/core/Spinner/ContentSpinner";
import Datatable from "../../../../../components/core/Datatable/Datatable";
import {apiGetSuperPartnerOrders} from "../../action";
import {useSelector} from "react-redux";
import DateClassic from "../../../../../components/Date/DateClassic";
import Button from "../../../../../components/Button/Button";
import {useNavigate} from "react-router-dom";
import Filters from "./Filters";
import TooltipBadge from "../../../../../components/TooltipBadge";
import {FiClipboard} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import {toast} from "react-hot-toast";
import PaginationComponent from "../../../../../components/PaginationComponent";


const OrderList = ({}) => {

    let [tickets, setTickets] = useState(null)
    let [pageSize, setPageSize] = useState(10);
    let [pageIndex, setPageIndex] = useState(0);
    let [pageCount, setPageCount] = useState(1);
    let [filters, setFilters] = useState({});
    let [loading, setLoading] = useState(false);

    const {t} = useTranslation()

    const {selectedPartner} = useSelector(state => state.user)
    const navigate = useNavigate()

    const getData = () => {
        setLoading(true);
        apiGetSuperPartnerOrders(selectedPartner.id, (data) => {
            setTickets(data.purchases);
            setPageCount(Math.ceil(data.maxCount / data.limit))
            setPageIndex(data.page - 1);
            setLoading(false)
        }, (error) => {
            setLoading(false);
            toast.error(error.message);
        }, {page: pageIndex + 1, limit: pageSize, ...filters})
    }

    useEffect(() => {
        getData();
    },[pageSize,pageIndex])

    useEffect(() => {
        setTickets(null);
        setPageIndex(0);
        getData();
    },[filters])

    const canNext = () => {
        return (pageIndex + 1) < pageCount;
    }

    const canPrevious = () => {
        return (pageIndex - 1) >= 0
    }

    const gotoPage = (pageIndex) => {
        setPageIndex(pageIndex);
    }

    const _renderTable = () => {
        if (!tickets) return null;
        return (
            <>
                <Datatable emptyText={t('order.noOrders')} data={tickets} withoutPagination={true}
                           disableSelection={true}
                           columns={[
                               {
                                   Header: '#ID',
                                   id: 'id',
                                   accessor: d => <span>
                                       <span>{d.id} {d.isStorno ? <sup
                                           className='text-red-900 uppercase'>{t("orders.cancelled")}</sup> : ""}</span>
                                       {d.note &&
                                           <div className='inline-block'>
                                               <TooltipBadge id={`purchase_${d.id}`} badgeText={<FiClipboard/>}
                                                             color={'white'} position={'right'}
                                                             component={<span>{d.note}</span>}/>
                                           </div>
                                       }

                                   </span>
                               },
                               {
                                   Header: t('subjects.single'),
                                   accessor: 'subject'
                               },
                               {
                                   Header: t('basics.date'),
                                   id: 'date',
                                   accessor: d => <DateClassic dateIso={d.date} format={'LLL'}/>
                               },
                               {
                                   Header: t('events.single'),
                                   id: 'event',
                                   accessor: d => d.eventName ?
                                       <span>{d.eventName} <sup><DateClassic dateIso={d.eventDate}
                                                                             format={'LLL'}/></sup></span> : ' -'
                               },
                               {
                                   Header: t('partners.single'),
                                   id: 'partner',
                                   accessor: d => <span>{d.partner ? d.partner : "-"}</span>
                               },
                               {
                                   Header: t('customers.single'),
                                   id: 'customer',
                                   accessor: d => <span>{d.customer ? d.customer : "-"}</span>
                               },
                               {
                                   Header: t('orders.pickupByUser'),
                                   accessor: 'user'
                               },
                               {
                                   Header: t('tickets.count'),
                                   accessor: 'ticketCount'
                               },
                               {
                                   Header: '',
                                   id: 'button',
                                   accessor: d => <Button
                                       onClick={() => navigate(`/admin/super-partner/orders/${d.id}`)}
                                       rounded={true} text={t('basics.detail')}/>
                               },
                           ]}/>
                <PaginationComponent
                    pageIndex={pageIndex}
                    previousPage={() => gotoPage(pageIndex - 1)}
                    nextPage={() => gotoPage(pageIndex + 1)}
                    gotoPage={gotoPage}
                    canPreviousPage={canPrevious()}
                    canNextPage={canNext()}
                    pageCount={pageCount}
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                />
            </>

        )
    }

    const onSearch = (values) => {
        setFilters(values);
    }

    return (
        <>
            <SectionTitle title={t('orders.plural')} subtitle={t('tickets.pickupedTickets')}/>
            <Filters onSearch={onSearch}/>
            <Widget title={'Data'}>
                <ContentSpinner isSpinning={loading}>
                    {_renderTable()}
                </ContentSpinner>
            </Widget>
        </>
    )
}

export default OrderList
