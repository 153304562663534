import React from "react";
import PropTypes from "prop-types";
import {PageWithText} from "./core/Datatable/Pagination";
import {useTranslation} from "react-i18next";

const PaginationComponent = ({pageIndex, previousPage,nextPage,gotoPage,canPreviousPage,canNextPage,pageCount,setPageSize,pageSize,pageOptions=[10,25,50,100]}) => {

    const {t} = useTranslation();

    return (
        <div className="flex lg:flex-row md:flex-row flex-col items-center justify-between my-4">
            <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
                {pageIndex !== 0 && (
                    <PageWithText onClick={() => gotoPage(0)}>{t("table.first")}</PageWithText>
                )}
                {canPreviousPage && (
                    <PageWithText onClick={() => previousPage()}>{t("table.previous")}</PageWithText>
                )}
                {canNextPage && (
                    <PageWithText onClick={() => nextPage()} disabled={!canNextPage}>
                        {t("table.next")}
                    </PageWithText>
                )}
                {pageIndex !== pageCount - 1 && (
                    <PageWithText
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}>
                        {t("table.last")}
                    </PageWithText>
                )}
            </div>

            <div dangerouslySetInnerHTML={{
                __html: t("table.pageOf", {
                    page: pageIndex + 1,
                    length: pageCount
                })
            }}>

            </div>
            <select
                className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}>
                {pageOptions.map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        {t("table.numberOfRecords", {pageSize: pageSize})}
                    </option>
                ))}
            </select>
        </div>
    )
}
PaginationComponent.propTypes = {
    pageIndex: PropTypes.number.isRequired,
    previousPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    gotoPage: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    canPreviousPage: PropTypes.bool.isRequired,
    canNextPage: PropTypes.bool.isRequired,
    pageCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageOptions: PropTypes.array
}
export default PaginationComponent;

