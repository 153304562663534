import React, {useEffect} from 'react'
import {useTable, useSortBy, usePagination, useRowSelect} from 'react-table'
import {FiChevronDown, FiChevronUp} from 'react-icons/fi'
import {PageWithText} from "./Pagination";
import {useTranslation} from "react-i18next";

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])
        return (
            <input
                type="checkbox"
                ref={resolvedRef}
                {...rest}
                className="form-checkbox h-4 w-4"
            />
        )
    }
)

const Datatable = ({columns, data, setSelectedItems, disableSelection, numberToSelect, withoutPagination = false}) => {
    const {t} = useTranslation()
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        toggleRowSelected,
        state: {pageIndex, pageSize, selectedRowIds}
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0, pageSize: 10}
        },
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            !disableSelection &&
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({row}) => {
                        return (
                            <>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </>
                        )
                    }
                },
                ...columns
            ])
        }
    )

    useEffect(() => {
        if (numberToSelect) {
            console.log(numberToSelect)
            for (let i = 0; i < numberToSelect; i++) {
                toggleRowSelected(i.toString(), true)
            }
        }
    }, [numberToSelect])

    useEffect(() => {
        if (!setSelectedItems) return undefined
        setSelectedItems(data.filter((item, index) =>
            Object.keys(selectedRowIds)
                .map(i => parseInt(i, 10))
                .includes(index)
        ))
    }, [selectedRowIds])


    // Render the UI for your table
    return (
        <>
            <div style={{
                scrollbarColor: "#62438e #835eb9",
                scrollbarWidth: "thin"
            }} className={"overflow-x-auto sm:scrollbar md:scrollbar"}>
                <table {...getTableProps()} className="table">
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <div className="flex flex-row items-center justify-start">
                                        <span>{column.render('Header')}</span>
                                        {/* Add a sort direction indicator */}
                                        <span className="ml-auto">
                      {column.isSorted ? (
                          column.isSortedDesc ? (
                              <FiChevronDown className="stroke-current text-2xs"/>
                          ) : (
                              <FiChevronUp className="stroke-current text-2xs"/>
                          )
                      ) : (
                          ''
                      )}
                    </span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className={row.isSelected ? 'bg-enigoo text-white' : ''}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {!withoutPagination &&
                <div className="flex lg:flex-row md:flex-row flex-col items-center justify-between my-4">
                    <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
                        {pageIndex !== 0 && (
                            <PageWithText onClick={() => gotoPage(0)}>{t("table.first")}</PageWithText>
                        )}
                        {canPreviousPage && (
                            <PageWithText onClick={() => previousPage()}>{t("table.previous")}</PageWithText>
                        )}
                        {canNextPage && (
                            <PageWithText onClick={() => nextPage()} disabled={!canNextPage}>
                                {t("table.next")}
                            </PageWithText>
                        )}
                        {pageIndex !== pageCount - 1 && (
                            <PageWithText
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}>
                                {t("table.last")}
                            </PageWithText>
                        )}
                    </div>

                    <div dangerouslySetInnerHTML={{
                        __html: t("table.pageOf", {
                            page: pageIndex + 1,
                            length: pageOptions.length
                        })
                    }}>

                    </div>

                    <select
                        className="form-select text-sm bg-white dark:bg-grey-800 dark:border-grey-800 outline-none shadow-none focus:shadow-none"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}>
                        {[10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {t("table.numberOfRecords", {pageSize: pageSize})}
                            </option>
                        ))}
                    </select>
                </div>
            }
        </>
    )
}

export default Datatable
