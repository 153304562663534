import Widget from "../../../../../components/core/Widget";
import {Form} from "react-final-form";
import TextField from "../../../../../components/FinalForm/TextField/TextField";
import PartnerField from "../../../../../components/FinalForm/ApiFields/PartnerField";
import Button from "../../../../../components/Button/Button";
import {FiSearch, FiTrash} from "react-icons/fi";
import React from "react";
import SelectField from "../../../../../components/FinalForm/SelectField/SelectField";
import DateClassic from "../../../../../components/Date/DateClassic";
import {useTranslation} from "react-i18next";
import EventSuperPartnerField from "../../../../../components/FinalForm/ApiFields/EventSuperPartnerField";

const Filters = ({onSearch}) => {

    const {t} = useTranslation()

    return (
        <Widget title={t('basics.filters')}>
            <Form
                onSubmit={onSearch} render={({handleSubmit, form}) => (
                <>
                    <div className='grid grid-cols-1 lg:grid-cols-3 lg:gap-4'>
                        <div className='w-full'>
                            <TextField label={'#ID'} name={'id'} inputType={'text'}/>
                        </div>
                        <div className='w-full'>
                            <TextField label={t('customers.single')} name={'customer'} inputType={'text'}/>
                        </div>
                        <div className='w-full'>
                            <PartnerField label={t('partners.plural')} name={'partners'} isClearable={true} isMulti={false}
                                          placeholder={t("placeholders.partners")}/>
                        </div>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-4'>
                        <div className='w-full'>
                            <EventSuperPartnerField label={t('events.single')} name={'eventId'} isClearable={true} isMulti={false}/>
                        </div>
                    </div>
                    <div className='text-right'>
                        <Button onClick={() => {
                            form.reset()
                            handleSubmit()
                        }} text={t('basics.resetForm')} rounded={true} outline={true} icon={<FiTrash/>}/>
                        <Button onClick={handleSubmit} text={t('basics.search')} rounded={true} icon={<FiSearch/>}/>
                    </div>
                </>
            )}/>
        </Widget>
    )
}

export default Filters
