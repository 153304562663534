import React, {useEffect, useState} from "react";
import {apiGetPartnerEvents} from "../../../pages/Admin/Events/action";
import {useSelector} from "react-redux";
import DateClassic from "../../Date/DateClassic";
import SelectField from "../SelectField/SelectField";

const EventPartnerField = ({...props}) =>{
    let [loading, setLoading] = useState(true)
    let [options, setOptions] = useState([])

    const {selectedPartner} = useSelector(state => state.user)

    const parseOptions = (options) => {
        let array = [];
        options.map(subject => {

            subject.events.map(item => {
                array.push({name: <span><span
                        className={'text-enigoo'}>{subject.name}</span> | {item.name}<sup><DateClassic
                        dateIso={item.startTime}
                        format={'LLL'}/></sup></span>, id: item.id})
            })
        })
        setOptions(array);
    }


    useEffect(() => {
        apiGetPartnerEvents(selectedPartner.id, (data) => {
            parseOptions(data)
            setLoading(false)
        }, (err) => {
        })
    }, []);

    return (
        <SelectField  {...props} options={options} loading={loading}/>
    )
}
export default EventPartnerField;