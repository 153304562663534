import {api, apiErrorParser} from "../../../api/api";

export const apiGetSpecificTickets = (superPartnerId, eventId, subjectId, values, success, error) => {
    api.post(`api/v1/super-partner/${superPartnerId}/event/tickets`, values, {params: {eventId, subjectId}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiGetPartnerSpecificTickets = (partnerId, eventId, values, success, error) => {
    api.post(`api/v1/partner/${partnerId}/events/${eventId}`, values)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiPartnerOrder = (partnerId, eventId, values, success, error, config = {}) => {
    api.post(`api/v1/partner/${partnerId}/events/${eventId}/order`, values, {...config})
        .then(response => success(response.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiPartnerOrderNoCustomer = (partnerId, eventId, values, success, error) => {
    api.post(`api/v1/partner/${partnerId}/events/${eventId}/order/no-customer`, values)
        .then(response => success(response.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiSuperPartnerOrder = (superPartnerId, eventId, subjectId, values, success, error, config = {}) => {
    api.post('api/v1/super-partner/' + superPartnerId + '/event/order', values, {
        params: {
            eventId,
            subjectId
        }, ...config
    })
        .then(response => success(response.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiSuperPartnerOrderNoCustomer = (superPartnerId, eventId, subjectId, values, success, error, config = {}) => {
    api.post('api/v1/super-partner/' + superPartnerId + '/event/order/no-customer', values, {
        params: {
            eventId,
            subjectId
        }
    })
        .then(response => success(response.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiGetPartnerOrders = (partnerId, params, success, error) => {
    api.get(`api/v1/partner/${partnerId}/orders`,{params:{...params}})
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}


export const apiGetSuperPartnerOrders = (superPartnerId, success, error,params) => {
    api.get('api/v1/super-partner/' + superPartnerId + '/orders', {params:{...params}} )
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiGetSuperPartnerOrder = (superPartnerId, orderId, success, error) => {
    api.get(`api/v1/super-partner/${superPartnerId}/orders/${orderId}`)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiGetPartnerOrder = (partnerId, orderId, success, error) => {
    api.get(`api/v1/partner/${partnerId}/orders/${orderId}`)
        .then(response => success(response.data.data))
        .catch(err => error(apiErrorParser(err.response)))
}

export const apiSuperPartnerOrderAction = (superPartnerId, orderId, params, success, error, config = {}) => {
    api.get(`api/v1/super-partner/${superPartnerId}/orders/${orderId}/action`, {
        params: {
            ...params
        }, ...config
    })
        .then(response => success(response.data))
        .catch(err => {
            error(apiErrorParser(err.response))
        })
}

export const apiPartnerOrderAction = (partnerId, orderId, params, success, error, config = {}) => {
    api.get(`api/v1/partner/${partnerId}/orders/${orderId}/action`, {
        params: {
            ...params
        }, ...config
    })
        .then(response => success(response.data))
        .catch(err => error(apiErrorParser(err.response)))
}
